import type * as React from 'react';

import { branch, compose, renderComponent, setDisplayName, withProps } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';
import createLoadableComponent from 'js/lib/createLoadableComponent';
import user from 'js/lib/user';

import useMarketingConsentData from 'bundles/user-consent/hooks/useMarketingConsentData';

const LoadableGDPRPage = createLoadableComponent(() => import('bundles/user-consent/components/GDPRPage'));

type IntermediateProps = {
  showGdprWallOverride: boolean;
};

type Props = IntermediateProps & {
  shouldShowGDPRMarketingConsent: boolean;
  isEnterpriseAdmin: boolean;
};

const branchGDPREligibility = (Component: React.ComponentType<Props>) =>
  compose<Props, {}>(
    connectToRouter((router) => {
      const queryParams = router.location.query;
      return { showGdprWallOverride: queryParams.showGdprWallOverride === 'true' };
    }),
    withProps<Props, IntermediateProps>((props) => {
      const { shouldShowGDPRMarketingConsent, isEnterpriseAdmin } = useMarketingConsentData();
      return { ...props, isEnterpriseAdmin, shouldShowGDPRMarketingConsent };
    }),
    branch<Props>(
      (props) => props.showGdprWallOverride || props.shouldShowGDPRMarketingConsent,
      renderComponent(LoadableGDPRPage)
    ),
    setDisplayName(`branchGDPRNaptime(${Component.displayName || Component.name})`)
  )(Component);

export default (Component: React.ComponentType<Props>) =>
  compose<Props, {}>(
    branch(() => user.isAuthenticatedUser(), renderComponent(branchGDPREligibility(Component))),
    setDisplayName(`branchGDPR(${Component.displayName || Component.name})`)
  )(Component);
