import type * as React from 'react';

import { branch, compose, renderComponent, setDisplayName, withProps } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';
import createLoadableComponent from 'js/lib/createLoadableComponent';
import user from 'js/lib/user';

import { shouldShowLegalConsolidationAuthFlow } from 'bundles/authentication/utils';
import useMarketingConsentData from 'bundles/user-consent/hooks/useMarketingConsentData';

const LoadableCCPAPage = createLoadableComponent(() => import('bundles/user-consent/components/CCPAPage'));

type IntermediateProps = {
  showCcpaWallOverride: boolean;
};

type Props = IntermediateProps & {
  shouldShowCCPAMarketingConsent: boolean;
  isEnterpriseAdmin: boolean;
};

const branchCCPAEligibility = (Component: React.ComponentType<Props>) =>
  compose<Props, {}>(
    connectToRouter((router) => {
      const queryParams = router.location.query;
      return {
        showCcpaWallOverride: queryParams.showCcpaWallOverride === 'true',
      };
    }),
    withProps<Props, IntermediateProps>((props) => {
      const { shouldShowCCPAMarketingConsent, isEnterpriseAdmin } = useMarketingConsentData();
      return { ...props, isEnterpriseAdmin, shouldShowCCPAMarketingConsent };
    }),
    branch<Props>(
      (props) => props.showCcpaWallOverride || props.shouldShowCCPAMarketingConsent,
      renderComponent(LoadableCCPAPage)
    ),
    setDisplayName(`branchCCPANaptime(${Component.displayName || Component.name})`)
  )(Component);

export default (Component: React.ComponentType<Props>) =>
  compose<Props, {}>(
    branch(
      () => user.isAuthenticatedUser() && !shouldShowLegalConsolidationAuthFlow(),
      renderComponent(branchCCPAEligibility(Component))
    ),
    setDisplayName(`branchCCPA(${Component.displayName || Component.name})`)
  )(Component);
